<template>
    <div id="spinner" class="invisible">
        <div class="spinner"></div>
    </div>
</template>

<script>
export default {

}
</script>